import { Outlet } from "react-router-dom";
import SideNav from "./side-nav";
import TopNav from "./top-nav";
import RateAndReview from "./rate-and-review";

function DashboardContent() {
  return (
    <div className="relative flex flex-row h-screen overflow-hidden gray-bg">
      <SideNav />
      <div className="relative flex-1 overflow-x-auto">
        <TopNav />
        <div className="relative w-full h-full px-6 pt-16">
          <Outlet />
        </div>
      </div>

      <RateAndReview />
    </div>
  );
}

export default DashboardContent;
