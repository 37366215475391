import { Rate } from "antd";
import { ReviewDetail } from "../../models";
import ReactTimeago from "react-timeago";

export default function ReviewsDetail({
  reviews,
}: {
  reviews: ReviewDetail[];
}) {
  return (
    <div>
      {reviews.length !== 0 ? (
        <div className="mt-10">
          <p className="mt-3 mb-3 text-lg font-semibold">Rating and reviews</p>
          {reviews.map((r, i) => (
            <div key={i} className="mb-5">
              <div className="flex items-center gap-3">
                <Rate value={r.rating} />
                <p className="font-semibold">{r.company}</p>
                <p>.</p>
                <p>
                  <ReactTimeago date={r.created_at} />
                </p>
              </div>
              <p className="mt-3">{r.review}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="p-4 text-center">No reviews</p>
      )}
    </div>
  );
}
