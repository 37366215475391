import ReactTimeago from "react-timeago";
import { MessageData } from "../../../models";

export default function LeftMessage({ message }: { message: MessageData }) {
  var d = new Date(message.message.createdAt);

  d.setHours(d.getHours() - 1);
  return (
    <div className="flex">
      <div className="max-w-[70%] rounded-md text-sm bg-white px-3 py-2">
        <p>{message.message.message}</p>
        <p className="mt-1 text-gray-500">
          <ReactTimeago date={d} />
        </p>
      </div>
    </div>
  );
}
