import { SelectData } from "../models";
import { Select } from "antd";
import _ from "lodash";

interface Props {
  data: SelectData[];
  setTtypes: (args: number[]) => void;
}
function MultipleSelect({ data, setTtypes }: Props) {
  return (
    <div className="multiple-select">
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        onChange={(e: any) => setTtypes(_.map(e, (i) => parseInt(i)))}
        options={data}
      />
    </div>
  );
}

export default MultipleSelect;
