import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { get_request } from "../../hooks/use-fetch";
import { useEffect, useState } from "react";
import { ReviewDetail } from "../../models";
import ReviewsDetail from "./reviews-detail";

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Cargo Reviews",
    children: "",
  },
  {
    key: "2",
    label: "Truck Reviews",
    children: "",
  },
];

export default function Reviews() {
  const [truckReviews, setTruckReviews] = useState<ReviewDetail[]>([]);
  const [cargoReviews, setCargoReviews] = useState<ReviewDetail[]>([]);
  const [index, setIndex] = useState("1");
  const get_reviews = async () => {
    const resp = await get_request("rating/all-reviews");
    if (resp.success !== 0) {
      setCargoReviews(resp.cargo);
      setTruckReviews(resp.truck);
    }
  };

  const onChange = (key: string) => {
    setIndex(key);
  };

  useEffect(() => {
    get_reviews();
  }, []);

  return (
    <div className="p-3 bg-white">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} centered />
      <div>
        <ReviewsDetail reviews={index === "1" ? cargoReviews : truckReviews} />
      </div>
    </div>
  );
}
