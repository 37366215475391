import { Upload, UploadProps, message } from "antd";
import { BASE_URL } from "../constants";

import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

export interface StringDictionary {
  [key: string]: string;
}

function FileUploader({
  title,
  uploads,
  setUploads,
}: {
  title: string;
  uploads: any;
  setUploads: (args: any) => void;
}) {
  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: BASE_URL + "/auth/file_upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        // eslint-disable-next-line array-callback-return
        info.fileList.map((upload) => {
          if (uploads[upload.uid] === undefined)
            uploads[upload.uid] = info.file.response.data[0];
          setUploads({ ...uploads });
        });
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      if (status === "removed") {
        delete uploads[info.file.uid];
        setUploads({ ...uploads });
      }
    },
    onDrop(e) {},
  };
  return (
    <div>
      <p className="mb-2">{title}</p>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
    </div>
  );
}

export default FileUploader;
