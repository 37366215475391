export function getKeyByValue(items: Object, selected: any) {
  for (const [key, value] of Object.entries(items)) {
    if (key === selected) return value;
  }
}

export const format_date = (date: string) => {
  const options: Object = { day: "2-digit", month: "short", year: "numeric" };
  // const options: Object = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString("en-US", options);

  // Split the formatted date into day, month, and year parts
  const [month, day, year] = formattedDate.split(" ");
  // const [month, day, year] = formattedDate.split(" ");

  // Convert the month abbreviation to uppercase
  const capitalizedMonth = month;

  // Return the formatted date with uppercase month abbreviation and desired format
  return `${day} ${capitalizedMonth} ${year}`;
  // return `${day} ${capitalizedMonth} ${year}`;
};

export const numberFormatter = (number: number) => {
  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(number);
};
