import _ from "lodash";
import { get_request } from "../hooks/use-fetch";
import { PaymentTerm, SelectData, TruckType } from "../models";

export const getTruckTypes = async () => {
  const resp = await get_request("truck/types");
  const typesData: TruckType[] = resp.data;

  const data: SelectData[] = _.map(typesData, (t) => {
    return { label: t.type, value: `${t.id}` };
  });
  return data;
};

export const getPaymentTerms = async () => {
  const resp = await get_request("finance/payment-term");
  const typesData: PaymentTerm[] = resp.data;

  const data: SelectData[] = _.map(typesData, (t) => {
    return { label: t.term, value: `${t.id}` };
  });
  return data;
};
