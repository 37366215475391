import "./App.css";
import "react-phone-number-input/style.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/auth-context";
import OpenRoute from "./hooks/open-routes";
import Login from "./pages/welcome/login";
import ProtectedRoute from "./hooks/protected-routes";
import DashboardContent from "./layout/dashboard-content";
import Dashboard from "./pages/dashboard";
import Register from "./pages/welcome/register";
import { RecoilRoot } from "recoil";
import PostTruck from "./pages/truck/post-truck";
import Trucks from "./pages/truck";
import Loads from "./pages/loads";
import ComingSoon from "./components/coming-soon";
import MyTrucks from "./pages/truck/my-truck";
import PostLoad from "./pages/loads/post-load";
import MyLoads from "./pages/loads/my-loads";
import Finance from "./pages/finance";
import Notifications from "./pages/notifications";
import Communication from "./pages/communication";
import Trips from "./pages/truck/trips";
import Reviews from "./pages/reviews";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <AuthProvider>
          <div className="App">
            <Routes>
              <Route
                path="/login"
                element={
                  <OpenRoute>
                    <Login />
                  </OpenRoute>
                }
              />
              <Route
                path="/register"
                element={
                  <OpenRoute>
                    <Register />
                  </OpenRoute>
                }
              />

              <Route
                path="*"
                element={
                  <ProtectedRoute>
                    <DashboardContent />
                  </ProtectedRoute>
                }
              >
                <Route path="" element={<Dashboard />} />
                <Route path="post-truck" element={<PostTruck />} />
                <Route path="post-load" element={<PostLoad />} />
                <Route path="search-truck" element={<Trucks />} />
                <Route path="search-load" element={<Loads />} />
                <Route path="my-trucks" element={<MyTrucks />} />
                <Route path="my-loads" element={<MyLoads />} />
                <Route path="chats" element={<Communication />} />
                <Route path="trips" element={<Trips />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="reviews" element={<Reviews />} />
                <Route path="finance" element={<Finance />} />
                <Route path="*" element={<ComingSoon />} />
              </Route>
            </Routes>
          </div>
        </AuthProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
