import React, { useState } from "react";
import ChatItem from "./chat-item";
import { get_request } from "../../hooks/use-fetch";
import { ConversationData } from "../../models";
import _ from "lodash";
import { socket } from "./socket";

function Chats() {
  const [data, setData] = useState<ConversationData[]>([]);

  const get_chats = async () => {
    const resp = await get_request("chat/conversations", {});

    if (resp.success !== 0) setData(resp.data);
  };

  const chatFilter = (user: any) => {
    if (
      _.filter(data, (c) => {
        return c.user.id === user;
      })
    )
      get_chats();
  };

  React.useEffect(() => {
    socket.connect();
    get_chats();
    const socketListener = {
      // online: (data) => chatFilter(data),
      // offline: (data) => chatFilter(data),
      message: (msg: any) => chatFilter(msg.message.user_id),
    };

    // socket.on("online", socketListener.online);
    // socket.on("offline", socketListener.offline);
    socket.on("message", socketListener.message);

    // return () => {
    //   socket.off("online", socketListener.online);
    //   socket.off("offline", socketListener.offline);
    //   socket.off("message", socketListener.message);
    // };
  }, []);
  return (
    <div className="flex flex-col gap-2 overflow-y-auto w-72 no-scroll-bar">
      <p className="sticky top-0 p-4 bg-white border">Chats</p>
      {data.map((r, i) => (
        <ChatItem key={i} value={r} />
      ))}
    </div>
  );
}

export default Chats;
