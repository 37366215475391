import React from "react";
import { MessageData } from "../../../models";
import ReactTimeago from "react-timeago";

export default function RightMessage({ message }: { message: MessageData }) {
  var d = new Date(message.message.createdAt);

  d.setHours(d.getHours() - 1);
  return (
    <div className="flex justify-end">
      <div className="max-w-[70%] bg-white rounded-md text-sm px-3 py-2">
        <p>{message.message.message}</p>
        <p className="mt-1 text-gray-500 text-end">
          <ReactTimeago date={d} />
        </p>
      </div>
    </div>
  );
}
