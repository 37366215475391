/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from "antd";
import { useEffect, useRef, useState } from "react";
import LeftMessage from "./message/left-message";
import RightMessage from "./message/right-message";
import { useRecoilValue } from "recoil";
import { chatStore } from "../../store";
import { ConversationData, MessageData } from "../../models";
import { get_request } from "../../hooks/use-fetch";
import { IoSendSharp } from "react-icons/io5";

import { socket } from "./socket";
import { getUser } from "../../hooks/user-token";

export default function MessageSection() {
  const chatData: any = useRecoilValue(chatStore);
  const chat: ConversationData = chatData;
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [data, setData] = useState<MessageData[]>([]);

  const [message, setMessage] = useState("");

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const messages = async () => {
    if (Object.keys(chatData).length !== 0) {
      const resp = await get_request("chat/messages", {
        chat_with: chat.user.id,
      });
      if (resp.success !== 0) setData(resp.data);
      scrollToBottom();
    }
  };

  useEffect(() => {
    messages();
  }, [chatData]);

  useEffect(() => {
    socket.connect();
    const socketListener = {
      message: (msg: any) => {
        if (msg.to === chat.user.id || msg.from === chat.user.id) messages();
      },
    };

    socket.on("message", socketListener.message);
    return () => {
      socket.off("message", socketListener.message);
    };
  }, [chatData]);

  const sendMessage = async () => {
    const user = JSON.parse(getUser());
    socket.emit("message", { to: chat.user.id, from: user.id, message });
    setMessage("");
  };
  return (
    <>
      {Object.keys(chatData).length !== 0 && (
        <div className="relative w-full h-full overflow-hidden bg-orange-600/50 no-scroll-bar">
          <div className="sticky top-0 flex items-center gap-3 p-2 font-semibold bg-white border">
            <img
              src="https://img.freepik.com/free-psd/3d-illustration-human-avatar-profile_23-2150671122.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1721520000&semt=ais_user"
              alt="avatar"
              className="object-cover rounded-full w-11 h-11 min-w-11"
            />
            <p>{chat.user.company}</p>
          </div>
          <div className="flex flex-col h-full gap-3 px-4 overflow-y-auto no-scroll-bar">
            <div className="h-36 min-h-36"></div>
            {data.map((r, i) => {
              if (r.fromSelf) return <RightMessage key={i} message={r} />;
              return <LeftMessage key={i} message={r} />;
            })}
            <div ref={messagesEndRef} />
          </div>
          <div className="sticky bottom-0 flex items-center w-full gap-3 p-4 bg-white border">
            <Input
              placeholder="Enter message here.."
              value={message}
              defaultValue={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {message.trim().length !== 0 && (
              <div
                className="p-2 text-white rounded-full cursor-pointer main-bg"
                onClick={sendMessage}
              >
                <IoSendSharp />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
