import { z } from "zod";

export const loginSchema = z.object({
  email: z.string().email().trim(),
  // password: z.string().trim(),
});

export const registerSchema = z.object({
  email: z.string().email().trim(),
  password: z
    .string()
    .min(1, { message: "This is required" })
    .min(5, { message: "Too short" }),
  first_name: z
    .string()
    .trim()
    .min(3, { message: "Must be atleast 3 characters" }),
  last_name: z
    .string()
    .trim()
    .min(3, { message: "Must be atleast 3 characters" }),
  company: z
    .string()
    .trim()
    .min(5, { message: "Must be atleast 5 characters" }),
});
