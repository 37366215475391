import React from "react";

function ComingSoon() {
  return (
    <div>
      <p>Coming soon</p>
    </div>
  );
}

export default ComingSoon;
