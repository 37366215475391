import { TruckData } from "../../models";
import { BsArrowRight } from "react-icons/bs";
import { format_date, numberFormatter } from "../../utils";
import TruckRating from "./truck-rating";
import StartChat from "../communication/start-chat";
import { getUser } from "../../hooks/user-token";

function TruckDetails({ data }: { data: TruckData }) {
  const user = JSON.parse(getUser());
  const Record = ({ label, detail }: { label: string; detail: string }) => {
    return (
      <tr>
        <td className="font-medium">{label}</td>
        <td>{detail}</td>
      </tr>
    );
  };

  return (
    <div className="px-4 py-2">
      <h3 className="flex items-center gap-4 mt-2 text-2xl font-bold">
        {data.origin} <BsArrowRight /> {data.destination}
      </h3>

      <div className="grid gap-4 mt-4 lg:grid-cols-2 xl:grid-cols-3">
        <div className="flex flex-col gap-3">
          <table className="border-0">
            <tbody>
              <Record label="Current Location" detail={data.location} />
              <Record
                label="Available From"
                detail={format_date(data.available_from)}
              />
              <Record
                label="Available To"
                detail={format_date(data.available_to)}
              />

              <Record
                label="Rate amount"
                detail={`KES. ${numberFormatter(data.min_rate)}`}
              />
            </tbody>
          </table>
        </div>
        <div className="flex flex-col gap-3">
          <table className="border-0">
            <tbody>
              <Record label="Truck type" detail={data.type} />
              <Record label="Weight" detail={numberFormatter(data.weight)} />
              <Record
                label="Full / Partial"
                detail={data.full ? "Full" : "Partial"}
              />
            </tbody>
          </table>
        </div>
        <div>
          <h5 className="text-3xl font-bold">{data.user?.company}</h5>
          <TruckRating truck={data} />
          {user.id !== data.user.id && (
            <StartChat
              chat_with={`${data.user?.id}`}
              company={`${data.user?.company}`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TruckDetails;
