import { BASE_URL } from "../constants";
import { getToken, removeToken } from "./user-token";

import _ from "lodash";

const response = async (response) => {
  const resp = await response.json();
  if (response.status === 403) {
    removeToken()
    window.location.reload()
  }
  else if (response.status !== 200) return { success: 0, message: resp.message }
  return resp
}

export async function post_request(service, data = {}) {
  try {
    const resp = await fetch(`${BASE_URL}/${service}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify(data),
    });


    return await response(resp)

  } catch (e) {
    if (e.message) return { success: 0, message: e.message };
    return { success: 0, message: "Request failed" };
  }
}



export async function get_request(service, params = {}) {
  try {
    const result = _.omitBy(params, (value) => {
      return value === null || value.length === 0;
    });

    let filters = _.join(
      _.map(Object.keys(result), (p) => `${p}=${result[p]}`),
      "&"
    );

    filters = filters.length === 0 ? "" : `?${filters}`;

    const resp = await fetch(`${BASE_URL}/${service}${filters}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    });


    return await response(resp)
  } catch (e) {
    if (e.message) return { success: 0, message: e.message };
    return { success: 0, message: "Request failed" };
  }
}
