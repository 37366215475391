import { useEffect, useState } from "react";
import { DatePicker, Checkbox, notification } from "antd";
import { LocationType, SelectData, TruckPost } from "../../models";
import LocationPicker from "../../components/location-picker";
import { post_request } from "../../hooks/use-fetch";
import _ from "lodash";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { getTruckTypes } from "../../services";
import { socket } from "../communication/socket";

const { RangePicker } = DatePicker;

function PostTruck() {
  document.title = "Post Truck";
  const navigate = useNavigate();
  const [destination, setDestination] = useState<LocationType>({
    location: "",
    lat: 0,
    lng: 0,
  });

  const [location, setLocation] = useState<LocationType>({
    location: "",
    lat: 0,
    lng: 0,
  });

  const [origin, setOrigin] = useState<LocationType>({
    location: "",
    lat: 0,
    lng: 0,
  });

  const [load, setLoad] = useState<boolean>(false);
  const [params, setParams] = useState<TruckPost>({
    available_from: "",
    available_to: "",
    full: true,
    truck_type: 0,
    weight: 0,
    radius: 0,
    contact: "",
    min_rate: 0,
    is_contact: false,
    is_email: false,
    comment: "",
    alarm: false,
  });

  const INPUT_STYLE =
    "w-full px-3 py-2 mt-1 text-sm border rounded-sm focus:outline-none";

  const [types, setTypes] = useState<SelectData[]>([]);

  const truckTypes = async () => {
    const data = await getTruckTypes();
    setTypes(data);
  };
  useEffect(() => {
    socket.connect();
    truckTypes();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (params.available_from.length === 0)
      notification.error({ message: "Please pick date available" });
    else if (params.available_to.length === 0)
      notification.error({ message: "Please pick date available" });
    else if (params.truck_type === 0)
      notification.error({ message: "Please choose a truck type" });
    else if (params.contact === undefined || params.contact.length === 0)
      notification.error({ message: "Please enter phone number" });
    else if (!isValidPhoneNumber(params.contact))
      notification.warning({ message: "Please enter a valid phone number" });
    else {
      let post_data: any = params;
      post_data = {
        ...post_data,
        ...{
          location: location.location,
          location_lat: location.lat,
          location_lng: location.lng,
          origin: origin.location,
          origin_lat: origin.lat,
          origin_lng: origin.lng,
          destination: destination.location,
          destination_lat: destination.lat,
          destination_lng: destination.lng,
        },
      };

      setLoad(true);
      const resp = await post_request("truck", post_data);
      setLoad(false);
      if (resp.success !== undefined && resp.success === 0) {
        notification.error({
          message: resp.message,
        });
        setLoad(false);
      } else {
        notification.success({
          message: resp.message,
        });
        socket.emit("truck", {});
        navigate("/my-trucks");
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="py-4">
        <div className="p-6 bg-white shadow-md">
          <h2 className="text-lg font-semibold">Post Truck</h2>
          <div className="grid grid-cols-1 gap-6 mt-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 regular">
            <div>
              <p>Available</p>
              <RangePicker
                className="w-full mt-1 rounded-none h-9"
                onChange={(e, p) => {
                  params.available_from = p[0];
                  params.available_to = p[1];
                  setParams({ ...params });
                }}
              />
            </div>

            <div>
              <p>Full / Patial</p>
              <select
                required
                className={INPUT_STYLE}
                onChange={(e) =>
                  setParams({ ...params, ...{ full: e.target.value === "1" } })
                }
              >
                <option value={1}>Full</option>
                <option value={0}>Partial</option>
              </select>
            </div>
            <div>
              <p>Truck type</p>
              <select
                required
                defaultValue={""}
                className={INPUT_STYLE}
                onChange={(e) => {
                  const truckType: any = e.target.value;
                  setParams({
                    ...params,
                    ...{ truck_type: parseInt(truckType) },
                  });
                }}
              >
                <option value="" disabled>
                  Choose truck type
                </option>
                {_.map(types, (t, i) => (
                  <option value={t.value} key={i}>
                    {t.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <p>Weight in Tons</p>
              <input
                required
                type="number"
                className={INPUT_STYLE}
                placeholder="Truck weight"
                onChange={(e) => {
                  const weight: any = e.target.value;
                  setParams({ ...params, ...{ weight: parseFloat(weight) } });
                }}
              />
            </div>

            <div>
              <p>Rate</p>
              <input
                required
                type="number"
                className={INPUT_STYLE}
                placeholder="Rate Amount"
                onChange={(e) => {
                  const weight: any = e.target.value;
                  setParams({ ...params, ...{ min_rate: parseFloat(weight) } });
                }}
              />
            </div>
            <div>
              <p>Prefered Contact</p>
              <div className="phone-inputs">
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="KE"
                  value={params.contact}
                  onChange={(e: any) =>
                    setParams({ ...params, ...{ contact: e } })
                  }
                />
              </div>
            </div>
            <div>
              <p>Comments</p>
              <input
                type="text"
                className={INPUT_STYLE}
                placeholder="Comments..."
                onChange={(e) =>
                  setParams({ ...params, ...{ comment: e.target.value } })
                }
              />
            </div>

            <div>
              <Checkbox
                onChange={(e) =>
                  setParams({ ...params, ...{ alarm: e.target.checked } })
                }
              >
                Create Alarm (Radius miles)
              </Checkbox>
              <input
                required
                type="text"
                className={INPUT_STYLE}
                placeholder="Radius (Km)"
                onChange={(e) => {
                  const radius: any = e.target.value;
                  setParams({ ...params, ...{ radius: parseFloat(radius) } });
                }}
              />
              <p>Radius limit is 500 Km</p>
            </div>
          </div>

          <p className="mt-5 mb-3 font-semibold">Location details</p>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 regular">
            <div>
              <p>Current location</p>
              <LocationPicker
                placeholder="Current location"
                location={location}
                setLocation={setLocation}
              />
            </div>
            <div>
              <p>Origin (city / state / zones)</p>
              <LocationPicker
                placeholder="Origin location"
                location={origin}
                setLocation={setOrigin}
              />
            </div>
            <div>
              <p>Destination (city / state / zones)</p>
              <LocationPicker
                placeholder="Destination location"
                location={destination}
                setLocation={setDestination}
              />
            </div>
          </div>

          <div className="mt-5 flexEnd">
            <button className="cancel-btn">Cancel</button>
            <button className="post-btn" type="submit" disabled={load}>
              {load ? "Submitting" : "Post"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default PostTruck;
