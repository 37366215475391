import React, { useState } from "react";
import { GoogleMap, MarkerF, InfoWindowF } from "@react-google-maps/api";
import _ from "lodash";
import truck from "../../assets/truck_red.png";
import cargo from "../../assets/cargo.png";
import { LoadData, TruckData } from "../../models";
import { get_request } from "../../hooks/use-fetch";
import { MapProvider } from "../../providers/map-provider";

const containerStyle = {
  width: "100%",
  height: "92vh",
};

const center = {
  lat: -1.286389,
  lng: 36.817223,
};

export default function Dashboard() {
  document.title = "Dashboard";
  const [data, setData] = useState<TruckData[]>([]);
  const [loads, setLoads] = useState<LoadData[]>([]);

  const [info, setInfo] = React.useState<any>({});

  const load = async () => {
    const resp = await get_request("cargo", {
      offset: 0,
      items: 10,
      order_by: "id",
      direction: "desc",
      available_from: null,
      available_to: null,
      full: null,
      weight: null,
      radius: null,
      lat: null,
      lng: null,
      truckType: null,
      mine: false,
    });

    if (resp.success !== undefined && resp.success === 0) {
    } else setLoads(resp.data);
  };

  const trucks = async () => {
    const resp = await get_request("truck", {
      offset: 0,
      items: 100,
      order_by: "id",
      direction: "desc",
      available_from: null,
      available_to: null,
      full: null,
      weight: null,
      radius: null,
      lat: null,
      lng: null,
      truckType: null,
      mine: false,
    });

    if (resp.success !== undefined && resp.success === 0) {
    } else setData(resp.data);
  };

  React.useEffect(() => {
    trucks();
    load();
  }, []);

  return (
    <div>
      <MapProvider>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={8}>
          {_.map(data, (t: TruckData, i) => (
            <div key={i}>
              <MarkerF
                icon={{
                  url: truck,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                position={{
                  lat: t.origin_lat,
                  lng: t.origin_lng,
                }}
                // onClick={() => setInfo(t)}
              />
              {Object.keys(info).length > 0 && info.id === t.id && (
                <InfoWindowF
                  position={{
                    lat: t.origin_lat,
                    lng: t.origin_lng,
                  }}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -25),
                  }}
                  onCloseClick={() => setInfo({})}
                >
                  <div className="marker_pop_up">
                    <h6 className="font-medium">{info.truck_no}</h6>
                    <p className="font-medium">Driver : {info.driver_name}</p>
                    <p className="mt-2">{info.type}</p>

                    <p>
                      Tel: <a href={`tel:+${info.phone}`}>+{info.phone}</a>
                    </p>
                  </div>
                </InfoWindowF>
              )}
            </div>
          ))}

          {_.map(loads, (t: LoadData, i) => (
            <div key={i}>
              <MarkerF
                icon={{
                  url: cargo,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                position={{
                  lat: t.origin_lat,
                  lng: t.origin_lng,
                }}
                // onClick={() => setInfo(t)}
              />
              {Object.keys(info).length > 0 && info.id === t.id && (
                <InfoWindowF
                  position={{
                    lat: t.origin_lat,
                    lng: t.origin_lng,
                  }}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -25),
                  }}
                  onCloseClick={() => setInfo({})}
                >
                  <div className="marker_pop_up">
                    <h6 className="font-medium">{info.truck_no}</h6>
                    <p className="font-medium">Driver : {info.driver_name}</p>
                    <p className="mt-2">{info.type}</p>

                    <p>
                      Tel: <a href={`tel:+${info.phone}`}>+{info.phone}</a>
                    </p>
                  </div>
                </InfoWindowF>
              )}
            </div>
          ))}
        </GoogleMap>
      </MapProvider>
    </div>
  );
}
