/* eslint-disable react-hooks/exhaustive-deps */
import { LuLayoutDashboard } from "react-icons/lu";
import { MdOutlineContentPasteSearch } from "react-icons/md";
import { FaPersonWalkingLuggage, FaRegMessage } from "react-icons/fa6";
import { BsTruck } from "react-icons/bs";
import { GiReceiveMoney } from "react-icons/gi";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { menuStore } from "../store";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { IoNotificationsOutline } from "react-icons/io5";
import { removeToken } from "../hooks/user-token";
import logo from "../assets/logo.png";

import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useEffect, useState } from "react";

type MenuItem = Required<MenuProps>["items"][number];

const { confirm } = Modal;

const ICON_STYLE = "text-xl";

const items: MenuItem[] = [
  {
    key: "",
    icon: <LuLayoutDashboard className={ICON_STYLE} />,
    label: "Dashboard",
  },
  {
    key: "loads",
    label: "Loads",

    icon: <MdOutlineContentPasteSearch className={ICON_STYLE} />,
    children: [
      { key: "search-load", label: "Search Load" },
      { key: "post-load", label: "Post load" },
      { key: "my-loads", label: "My Loads" },
    ],
  },
  {
    key: "trucks",
    label: "Trucks",
    icon: <BsTruck className={ICON_STYLE} />,
    children: [
      { key: "search-truck", label: "Search Truck" },
      { key: "post-truck", label: "Post Truck" },
      { key: "my-trucks", label: "My Trucks" },
      { key: "trips", label: "Trips" },
    ],
  },
  {
    key: "chats",
    icon: <FaRegMessage className={ICON_STYLE} />,
    label: "Chats",
  },
  {
    key: "finance",
    icon: <GiReceiveMoney className={ICON_STYLE} />,
    label: "Finance",
  },
  {
    key: "reviews",
    icon: <FaPersonWalkingLuggage className={ICON_STYLE} />,
    label: "Reviews",
  },
  {
    key: "notifications",
    icon: <IoNotificationsOutline className={ICON_STYLE} />,
    label: "Notifications",
  },
];

interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}

const getLevelKeys = (items1: LevelKeysProps[]) => {
  const key: Record<string, number> = {};
  const func = (items2: LevelKeysProps[], level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const levelKeys = getLevelKeys(items as LevelKeysProps[]);

function SideNav() {
  const [menu, setMenu] = useRecoilState(menuStore);

  const navigate = useNavigate();

  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    setMenu(pathname.replace("/", ""));
  }, [pathname]);

  const logoutConfirm = () => {
    confirm({
      title: "Do you want to logout?",
      icon: <ExclamationCircleFilled />,
      content:
        "When you click ok, you will be automatically logged out of the system",
      onOk() {
        removeToken();
        window.location.reload();
      },
      onCancel() {},
    });
  };

  const [stateOpenKeys, setStateOpenKeys] = useState(["1"]);

  const onOpenChange: MenuProps["onOpenChange"] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <nav className="hidden w-64 h-screen overflow-x-hidden overflow-y-auto text-white main-bg regular md:block no-scroll-bar">
      <div className="justify-between h-full p-2 flexColumn ">
        <div>
          <div className="justify-center flexCenter">
            <img src={logo} alt="Rudi Load Board" className="max-h-24" />
          </div>

          <Menu
            mode="inline"
            // defaultSelectedKeys={[""]}
            selectedKeys={[menu]}
            openKeys={stateOpenKeys}
            theme="dark"
            onOpenChange={onOpenChange}
            onSelect={(e) => {
              navigate(`/${e.key}`);
              setMenu(e.key);
            }}
            items={items}
          />
        </div>

        <button className="side-menu-item" onClick={logoutConfirm}></button>
      </div>
    </nav>
  );
}

export default SideNav;
