import { useState, useEffect } from "react";
import { get_request, post_request } from "../../hooks/use-fetch";
import { BidTripData, TruckData } from "../../models";
import _ from "lodash";
import ReactTimeago from "react-timeago";
import { format_date, numberFormatter } from "../../utils";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, notification } from "antd";

const { confirm } = Modal;

export default function Trips() {
  document.title = "Trip Bids";
  const [data, setData] = useState<TruckData[]>([]);
  const [selected, setSelected] = useState<BidTripData>({
    id: 0,
    user_id: 0,
    cargo: "",
    weight: 0,
    amount: 0,
    available_from: "",
    available_to: "",
    truck_type: [],
    full: false,
    origin: "",
    alpha3: "",
    destination: "",
    term: "",
    origin_lat: 0,
    origin_lng: 0,
    destination_lat: 0,
    destination_lng: 0,
    created_at: "",
    update_at: "",
    user: null,
    delivered: 0,
    comment: "",
    status: 0,
    bid: {
      id: 0,
      status: 0,
    },
    bid_requests: [],
    rating: { rating: 0, number: 0 },
  });
  const [params, setParam] = useState({
    offset: 0,
    items: 10,
  });
  const trucks = async () => {
    const resp = await get_request("truck/trips", params);

    if (resp.success !== undefined && resp.success === 0) {
    } else setData(resp.data);
  };

  useEffect(() => {
    trucks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const cancelBid = (bidId: number) => {
    confirm({
      title: "Do you want to cancel your bid?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, your bid will be cancelled",
      onOk() {
        return new Promise(async (resolve, reject) => {
          const resp = await post_request("cargo/cancel_bid", {
            bid_id: bidId,
          });

          if (resp.success === 0) notification.error({ message: resp.message });
          else {
            notification.success({ message: resp.message });
            window.location.reload();
            resolve(true);
          }
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  return (
    <div>
      <div className="p-4 bg-white shadow-md">
        <div className="flexBetween">
          <h2 className="font-semibold">My Bids</h2>
        </div>
        <div className="w-full mt-3 overflow-x-auto data-tables">
          <table>
            <thead>
              <tr className="uppercase">
                <th>AGE</th>
                <th>Cargo</th>
                <th>Available From</th>
                <th>Available To</th>
                <th>Amount (KES)</th>
                <th>Comment</th>
                <th>Status</th>

                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {_.map(data, (t: BidTripData, i: number) => (
                <>
                  <tr
                    key={i}
                    onClick={() => setSelected(t)}
                    className={`${selected.id === t.id && "active_row"}`}
                  >
                    <td>
                      <ReactTimeago date={t.created_at} />
                    </td>

                    <td>{t.cargo}</td>
                    <td>{format_date(t.available_from)}</td>
                    <td>{format_date(t.available_to)}</td>

                    <td className="text-end">{numberFormatter(t.amount)}</td>
                    <td>{t.comment}</td>
                    <td>
                      {t.status === 0 && "Pending"}
                      {t.status === 1 && t.delivered === 0 && "Accepted"}
                      {t.status === 1 && t.delivered === 1 && "Delivered"}
                      {t.status === 2 && "Declined"}
                      {t.status === 3 && "Cancelled"}
                    </td>
                    <td>
                      {t.status === 0 && (
                        <button
                          className="cancel-btn"
                          onClick={() => cancelBid(t.id)}
                        >
                          Cancel Bid
                        </button>
                      )}
                    </td>
                  </tr>
                  {selected.id === t.id && (
                    <tr key={i}>
                      <td colSpan={10}>
                        {/* <TruckDetails data={selected} /> */}
                      </td>
                    </tr>
                  )}
                </>
              ))}
              {data.length === 0 && (
                <tr>
                  <td colSpan={9} className="text-center">
                    No Data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
