/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Modal, message, notification } from "antd";
import { CurrencyData, FinanceData, LocationType } from "../../models";
import { INPUT_STYLE } from "../../constants";
import LocationPicker from "../../components/location-picker";
import FileUploader from "../../components/file-uploader";
import { post_request } from "../../hooks/use-fetch";

export default function RequestFinance({
  currency,
}: {
  currency: CurrencyData[];
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoice, setInvoice] = useState<any>([]);
  const [packingList, setPackingList] = useState<any>([]);
  const [destination, setDestination] = useState<LocationType>({
    location: "",
    lat: 0,
    lng: 0,
  });

  const [origin, setOrigin] = useState<LocationType>({
    location: "",
    lat: 0,
    lng: 0,
  });

  const [data, setData] = useState<FinanceData>({
    currency_id: 0,
    plate_no: "",
    invoice: [],
    packing_list: [],
    amount: 0,
    origin: null,
    origin_lat: null,
    origin_lng: null,
    destination: null,
    destination_lat: null,
    destination_lng: null,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    /** Parameter validations */
    if (data.plate_no.length === 0) message.error("Please enter number plate");
    if (data.currency_id === 0) message.error("Please choose a currency");
    else if (data.amount === 0) message.error("Please enter amount");
    else if (data.origin_lat === null) message.error("Please enter origin");
    else if (data.destination_lat === null)
      message.error("Please enter destination");
    else if (Object.values(invoice).length === 0)
      message.error("Please upload an invoice");
    else if (Object.values(packingList).length === 0)
      message.error("Please upload a packing list");
    else {
      const resp = await post_request("finance", {
        ...data,
        ...{
          invoice: Object.values(invoice),
          packing_list: Object.values(packingList),
        },
      });

      if (resp.success !== undefined && resp.success === 0) {
        notification.error({
          message: resp.message,
        });
      } else {
        notification.success({
          message: resp.message,
        });
        window.location.reload();
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    data.origin = origin.location;
    data.origin_lat = origin.lat === 0 ? null : origin.lat;
    data.origin_lng = origin.lng === 0 ? null : origin.lng;
    setData({ ...data });
  }, [origin]);

  useEffect(() => {
    data.destination = origin.location;
    data.destination_lat = origin.lat === 0 ? null : origin.lat;
    data.destination_lng = origin.lng === 0 ? null : origin.lng;
    setData({ ...data });
  }, [destination]);

  return (
    <>
      <button className="post-btn" onClick={showModal}>
        Request Finance
      </button>

      <Modal
        title="Request Finance"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        width={1000}
      >
        <div className="grid grid-cols-1 gap-6 mt-3 md:grid-cols-2 regular">
          <div>
            <p>Truck number plate</p>
            <input
              required
              type="text"
              className={INPUT_STYLE}
              placeholder="Truck number plate"
              onChange={(e) => {
                const value: any = e.target.value;
                setData({ ...data, ...{ plate_no: value } });
              }}
            />
          </div>

          <div>
            <p>Currency</p>
            <select
              required
              className={INPUT_STYLE}
              onChange={(e) => {
                const value: any = e.target.value;
                setData({ ...data, ...{ currency_id: parseInt(value) } });
              }}
            >
              <option>Choose a currency</option>
              {currency.map((c, i) => (
                <option value={c.number} key={i}>
                  {c.langEN}({c.alpha3})
                </option>
              ))}
            </select>
          </div>

          <div>
            <p>Amount</p>
            <input
              required
              type="number"
              className={INPUT_STYLE}
              placeholder="Amount"
              onChange={(e) => {
                const value: any = e.target.value;
                setData({ ...data, ...{ amount: parseFloat(value) } });
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 pb-10 mt-3 md:grid-cols-2 regular">
          <div>
            <p>Origin (city / state / zones)</p>
            <LocationPicker
              placeholder="Origin location"
              location={origin}
              setLocation={setOrigin}
            />
          </div>
          <div>
            <p>Destination (city / state / zones)</p>
            <LocationPicker
              placeholder="Destination location"
              location={destination}
              setLocation={setDestination}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 pb-10 mt-3 md:grid-cols-2 regular">
          <FileUploader
            uploads={invoice}
            setUploads={setInvoice}
            title="Invoice"
          />
          <FileUploader
            uploads={packingList}
            setUploads={setPackingList}
            title="Packing List"
          />
        </div>
      </Modal>
    </>
  );
}
