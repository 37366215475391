import { useState, useEffect } from "react";
import { get_request } from "../../hooks/use-fetch";
import {
  CurrencyData,
  FinanceData,
  FinanceDataList,
  FinanceListData,
  LoadData,
} from "../../models";
import _ from "lodash";
import { Checkbox } from "antd";
import ReactTimeago from "react-timeago";
import { format_date } from "../../utils";
import RequestFinance from "./request";

export default function Finance() {
  document.title = "Finance";
  const [data, setData] = useState<LoadData[]>([]);
  const [currency, setCurrency] = useState<CurrencyData[]>([]);

  const [params, setParam] = useState<FinanceListData>({
    offset: 0,
    items: 10,
    order_by: "id",
    direction: "desc",
    mine: true,
  });

  const get_finance = async () => {
    const resp = await get_request("finance", params);

    if (resp.success !== undefined && resp.success === 0) {
      //   console.log(resp);
    } else setData(resp.data);
  };

  const get_currency = async () => {
    const resp = await get_request("finance/currency", {});

    if (resp.success !== undefined && resp.success === 0) {
      //   console.log(resp);
    } else setCurrency(resp.data);
  };

  useEffect(() => {
    get_finance();
    get_currency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <>
      <div className="p-4 bg-white shadow-md">
        <div className="flexBetween">
          <h2 className="font-semibold">Loads</h2>
          <RequestFinance currency={currency} />
        </div>
        <div className="w-full mt-3 overflow-x-auto data-table">
          <table>
            <thead>
              <tr className="uppercase">
                <td></td>
                <td>AGE</td>
                <td>Amount</td>
                <td>Origin</td>
                <td>Destination</td>
                <td>Status</td>
              </tr>
            </thead>

            <tbody>
              {data.length === 0 && (
                <tr>
                  <td colSpan={13} className="text-center">
                    No Data available
                  </td>
                </tr>
              )}
              {_.map(data, (t: FinanceDataList, i: number) => (
                <tr key={i}>
                  <td>
                    <Checkbox />
                  </td>
                  <td>
                    <ReactTimeago date={t.created_at} />
                  </td>
                  <td>
                    {t.alpha3} {t.amount}
                  </td>
                  <td>{t.origin}</td>
                  <td>{t.destination}</td>
                  <td>{t.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
