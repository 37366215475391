import { LoadData } from "../../models";
import { BsArrowRight } from "react-icons/bs";
import _ from "lodash";
import { format_date, numberFormatter } from "../../utils";
import { getUser } from "../../hooks/user-token";
import { useState } from "react";
import { Modal, message, notification } from "antd";
import { INPUT_STYLE } from "../../constants";
import { post_request } from "../../hooks/use-fetch";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Rating from "./rating";
import RateAndReviewTruck from "./rate-and-review-truck";
import StartChat from "../communication/start-chat";

const { confirm } = Modal;

function LoadDetail({ data }: { data: LoadData }) {
  const user = JSON.parse(getUser());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [bid, setBid] = useState({ load_id: data.id, amount: 0, comment: "" });

  const [load, setLoad] = useState<LoadData>(data);

  const Record = ({ label, detail }: { label: string; detail: string }) => {
    return (
      <tr>
        <td className="font-medium">{label}</td>
        <td>{detail}</td>
      </tr>
    );
  };

  const handleOk = async () => {
    if (
      isNaN(bid.amount) ||
      bid.amount <= 0 ||
      bid.amount.toString().length === 0
    )
      message.error("Please enter amount");
    else if (bid.comment.length === 0) message.error("Please enter a comment");
    else {
      const resp = await post_request("cargo/bid", bid);
      if (resp.success === 0) notification.error({ message: resp.message });
      else {
        notification.success({ message: resp.message });
        window.location.reload();
      }
      setIsModalOpen(false);
    }
  };

  const cancelBid = (bidId: number) => {
    confirm({
      title: "Do you want to cancel your bid?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, your bid will be cancelled",
      onOk() {
        return new Promise(async (resolve, reject) => {
          const resp = await post_request("cargo/cancel_bid", {
            bid_id: bidId,
          });

          if (resp.success === 0) notification.error({ message: resp.message });
          else {
            notification.success({ message: resp.message });
            setLoad({
              ...load,
              ...{
                bid: {
                  id: null,
                  status: null,
                },
              },
            });
            resolve(true);
          }
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const acceptBid = (bidId: number) => {
    confirm({
      title: "Do you want to accept this bid?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, the bid will be accepted",
      onOk() {
        return new Promise(async (resolve, reject) => {
          const resp = await post_request("cargo/accept_bid", {
            bid_id: bidId,
          });

          if (resp.success === 0) notification.error({ message: resp.message });
          else notification.success({ message: resp.message });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Modal
        title={`Bid for cargo ${load.cargo}`}
        open={isModalOpen}
        onOk={handleOk}
        maskClosable={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <div>
          <p>Amount (KES)</p>
          <input
            required
            type="number"
            className={INPUT_STYLE}
            placeholder="Enter you bid amount"
            onChange={(e) => {
              const data: any = e.target.value;
              setBid({ ...bid, ...{ amount: parseFloat(data) } });
            }}
          />
        </div>
        <div className="mt-3">
          <p>Comment</p>
          <textarea
            className={INPUT_STYLE}
            placeholder="Enter you bid amount"
            onChange={(e) => {
              setBid({ ...bid, ...{ comment: e.target.value } });
            }}
          ></textarea>
          <input required type="number" />
        </div>
      </Modal>
      <div className="px-4 py-2">
        <h3 className="flex items-center gap-4 mt-2 text-2xl font-bold">
          {load.origin} <BsArrowRight /> {load.destination}
        </h3>
        <div className="grid gap-4 mt-4 lg:grid-cols-2 xl:grid-cols-3">
          <div className="flex flex-col gap-3">
            <table className="border-0">
              <tbody>
                <Record label="Cargo" detail={load.cargo} />
                <Record
                  label="Load Available From"
                  detail={format_date(load.available_from)}
                />
                <Record
                  label="Load Available To"
                  detail={format_date(load.available_to)}
                />
                {load.amount > 0 && (
                  <Record
                    label="Amount"
                    detail={`${
                      load.alpha3 !== null ? load.alpha3 : "KES"
                    } ${numberFormatter(load.amount)}`}
                  />
                )}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col gap-3">
            <table>
              <tbody>
                <Record label="Load" detail={load.full ? "Full" : "Partial"} />

                <tr>
                  <td className="">Truck (s)</td>
                  <td>
                    {_.map(load.truck_type, (tr, i) => (
                      <p key={i}>
                        {"->"} {tr.type}
                      </p>
                    ))}
                  </td>
                </tr>

                <Record label="Weight" detail={load.weight + " Tons"} />
                <Record label="Payment Term" detail={load.term} />
              </tbody>
            </table>

            {user.id !== load.user_id && (
              <>
                {load.bid.status === 0 && (
                  <button
                    className="mt-6 cancel-btn"
                    onClick={() =>
                      cancelBid(load.bid.id == null ? 0 : load.bid.id)
                    }
                  >
                    Cancel Bid
                  </button>
                )}
                {load.bid.id == null && (
                  <button
                    className="mt-6 login-btn"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Submit Bid
                  </button>
                )}
              </>
            )}
          </div>
          <div>
            <h5 className="text-3xl font-bold">{load.user?.company}</h5>
            <Rating load={load} />
            {user.id !== load.user?.id && (
              <StartChat
                chat_with={`${load.user?.id}`}
                company={`${load.user?.company}`}
              />
            )}
          </div>
        </div>

        {user.id === load.user_id && load.bid_requests.length > 0 && (
          <div className="w-full pt-3 mt-3 mb-5 overflow-x-auto data-tables">
            <table>
              <thead>
                <tr>
                  <th>Driver</th>
                  <th>Amount (KES)</th>
                  <th>Comment</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {_.map(load.bid_requests, (b, i) => (
                  <tr key={i}>
                    <td>
                      {b.first_name} {b.last_name}
                    </td>
                    <td>{numberFormatter(b.amount)}</td>
                    <td>{b.comment}</td>
                    <td>
                      {b.status === 0 && "Pending"}
                      {b.status === 1 && load.delivered === 0 && "Accepted"}
                      {b.status === 1 && load.delivered === 1 && "Delivered"}
                      {b.status === 2 && "Declined"}
                      {b.status === 3 && "Cancelled"}
                    </td>
                    <td>
                      {b.status === 0 && (
                        <button
                          className="action-btn"
                          onClick={() => acceptBid(b.id)}
                        >
                          Accept
                        </button>
                      )}

                      {b.status === 1 && load.delivered === 0 && (
                        <RateAndReviewTruck bid={b} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default LoadDetail;
