import { useEffect, useState } from "react";
import { DatePicker, notification } from "antd";
import { CurrencyData, LoadPost, LocationType, SelectData } from "../../models";
import LocationPicker from "../../components/location-picker";
import { get_request, post_request } from "../../hooks/use-fetch";
import { useNavigate } from "react-router-dom";
import { getPaymentTerms, getTruckTypes } from "../../services";
import MultipleSelect from "../../components/multiple-select";
import { INPUT_STYLE } from "../../constants";
import { socket } from "../communication/socket";

const { RangePicker } = DatePicker;

function PostLoad() {
  document.title = "Post Truck";
  const navigate = useNavigate();
  const [currency, setCurrency] = useState<CurrencyData[]>([]);
  const [destination, setDestination] = useState<LocationType>({
    location: "",
    lat: 0,
    lng: 0,
  });

  const [origin, setOrigin] = useState<LocationType>({
    location: "",
    lat: 0,
    lng: 0,
  });

  const [load, setLoad] = useState<boolean>(false);
  const [params, setParams] = useState<LoadPost>({
    available_from: "",
    available_to: "",
    full: true,
    truck_type: [],
    weight: 0,
    payment_term: 0,
    currency: 0,
    amount: 0,
    cargo: "",
  });

  const [types, setTypes] = useState<SelectData[]>([]);
  const [terms, setTerms] = useState<SelectData[]>([]);

  const truckTypes = async () => {
    const data = await getTruckTypes();
    setTypes(data);
  };

  const paymentTerms = async () => {
    const data = await getPaymentTerms();
    setTerms(data);
  };

  const get_currency = async () => {
    const resp = await get_request("finance/currency", {});

    if (resp.success !== undefined && resp.success === 0) {
      //   console.log(resp);
    } else setCurrency(resp.data);
  };

  useEffect(() => {
    socket.connect();
    truckTypes();
    paymentTerms();
    get_currency();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (params.available_from.length === 0)
      notification.error({ message: "Please pick date available" });
    else if (params.available_to.length === 0)
      notification.error({ message: "Please pick date available" });
    else if (params.truck_type.length === 0)
      notification.error({ message: "Please choose a truck type" });
    else if (params.payment_term === 0)
      notification.error({ message: "Please choose a a payment term" });
    else {
      let post_data: any = params;
      post_data = {
        ...post_data,
        ...{
          origin: origin.location,
          origin_lat: origin.lat,
          origin_lng: origin.lng,
          destination: destination.location,
          destination_lat: destination.lat,
          destination_lng: destination.lng,
        },
      };

      setLoad(true);
      const resp = await post_request("cargo", post_data);
      setLoad(false);
      if (resp.success !== undefined && resp.success === 0) {
        notification.error({
          message: resp.message,
        });
        setLoad(false);
      } else {
        notification.success({
          message: resp.message,
        });

        socket.emit("cargo", {});
        navigate("/my-loads");
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="py-4">
        <div className="p-6 bg-white shadow-md">
          <h2 className="text-lg font-semibold">Post Load</h2>
          <div className="grid grid-cols-1 gap-6 mt-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 regular">
            <div>
              <p>Cargo</p>
              <input
                required
                type="text"
                className={INPUT_STYLE}
                placeholder="Cargo name"
                onChange={(e) => {
                  setParams({ ...params, ...{ cargo: e.target.value } });
                }}
              />
            </div>
            <div>
              <p>Available</p>
              <RangePicker
                className="w-full mt-1 rounded-none h-9"
                onChange={(e, p) => {
                  params.available_from = p[0];
                  params.available_to = p[1];
                  setParams({ ...params });
                }}
              />
            </div>

            <div>
              <p>Full / Patial</p>
              <select
                required
                className={INPUT_STYLE}
                onChange={(e) =>
                  setParams({ ...params, ...{ full: e.target.value === "1" } })
                }
              >
                <option value={1}>Full</option>
                <option value={0}>Partial</option>
              </select>
            </div>
            <div>
              <p>Truck type</p>

              <MultipleSelect
                data={types}
                setTtypes={(args) =>
                  setParams({ ...params, ...{ truck_type: args } })
                }
              />
            </div>
            <div>
              <p>Weight in Tons</p>
              <input
                required
                type="number"
                className={INPUT_STYLE}
                placeholder="Cargo weight"
                onChange={(e) => {
                  const weight: any = e.target.value;
                  setParams({ ...params, ...{ weight: parseFloat(weight) } });
                }}
              />
            </div>

            <div>
              <p>Currency</p>
              <select
                required
                className={INPUT_STYLE}
                onChange={(e) => {
                  const value: any = e.target.value;
                  setParams({ ...params, ...{ currency: parseInt(value) } });
                }}
              >
                <option disabled>Choose a currency</option>
                {currency.map((c, i) => (
                  <option value={c.number} key={i}>
                    {c.langEN} ({c.alpha3})
                  </option>
                ))}
              </select>
            </div>

            <div>
              <p>Amount (optional)</p>
              <input
                type="number"
                className={INPUT_STYLE}
                placeholder="Amount"
                onChange={(e) => {
                  const weight: any = e.target.value;
                  setParams({ ...params, ...{ amount: parseFloat(weight) } });
                }}
              />
            </div>

            <div>
              <p>Payment Term</p>
              <select
                required
                className={INPUT_STYLE}
                onChange={(e) => {
                  const data: any = e.target.value;
                  setParams({ ...params, ...{ payment_term: parseInt(data) } });
                }}
              >
                <option value="" selected disabled>
                  Choose Payment Term
                </option>

                {terms.map((t, i) => (
                  <option key={i} value={t.value}>
                    {t.label}
                  </option>
                ))}
                <option value={0}>Partial</option>
              </select>
            </div>
          </div>

          <p className="mt-5 mb-3 font-semibold">Location details</p>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 regular">
            <div>
              <p>Loading point</p>
              <LocationPicker
                placeholder="Origin location"
                location={origin}
                setLocation={setOrigin}
              />
            </div>
            <div>
              <p>Offloading point</p>
              <LocationPicker
                placeholder="Destination location"
                location={destination}
                setLocation={setDestination}
              />
            </div>
          </div>

          <div className="mt-5 flexEnd">
            <button className="cancel-btn">Cancel</button>
            <button className="post-btn" type="submit" disabled={load}>
              {load ? "Submitting" : "Post"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default PostLoad;
