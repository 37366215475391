import { useEffect, useState } from "react";
import Chats from "./chats";
import MessageSection from "./message-section";
import { socket } from "./socket";

export default function Communication() {
  document.title = "Chats";

  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    socket.connect();
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    // function onFooEvent(value) {
    //   setFooEvents((previous) => [...previous, value]);
    // }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    // socket.on("foo", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      // socket.off("foo", onFooEvent);
    };
  }, []);

  return (
    <>
      <div className="flex h-full gap-3">
        <Chats />
        <div className="flex flex-1">
          <MessageSection />
          {/* <div className="flex-1 w-full bg-blue-400">
          <p>Communication</p>
        </div> */}
        </div>
      </div>
    </>
  );
}
