import { useState } from "react";
import Input from "../../components/input";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import useToken from "../../hooks/user-token";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { registerSchema } from "../../schemas";
import { notification } from "antd";
import { post_request } from "../../hooks/use-fetch";
import { Link } from "react-router-dom";

import bg from "../../assets/bg2.svg";

function Register() {
  document.title = "Register";
  const [phone, setPhone] = useState<any>("");
  const [pwd, setPwd] = useState("");

  const { setToken } = useToken();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<z.input<typeof registerSchema>>({
    resolver: zodResolver(registerSchema, {}, { raw: true }),
  });

  const onSubmit: SubmitHandler<z.input<typeof registerSchema>> = async (
    data
  ) => {
    let post_data: any = data;
    if (!phone) notification.error({ message: "Please enter a phone number" });
    else if (!isValidPhoneNumber(phone))
      notification.warning({ message: "Please enter a valid phone number" });
    else if (pwd !== data.password)
      notification.warning({ message: "Passwords do not match" });
    else {
      delete post_data.confirmPassword;
      const resp = await post_request("auth/register", {
        ...post_data,
        ...{ phone, contact_via_email: false },
      });

      if (resp.success !== undefined && resp.success === 0)
        notification.error({
          message: resp.message,
        });
      else {
        setToken(resp.token);
        window.location.reload();
      }
    }
  };

  return (
    <div
      className="p-6 bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="gap-3 lg:flexColumn">
        <div className="container-box">
          <div className="w-full h-screen lg:flexCenter">
            <div className="w-full">
              <div className="grid items-center w-full gap-10 mt-10 lg:grid-cols-2">
                <div className="text-white">
                  <h1 className="text-4xl font-bold capitalize">
                    Africa's No 1 load board and factoring Platform.
                  </h1>
                  <p className="mt-6 text-lg md:text-xl ">
                    We are excited to be at the forefront of innovation in the
                    transportation industry, and we are committed to creating a
                    more sustainable future for our customers, our drivers, and
                    the planet. Join us in our mission to revolutionize cargo
                    transportation in Africa.
                  </p>
                </div>
                <div className="p-10 bg-white rounded-md">
                  <h2 className="mb-4 text-xl font-semibold">Register here</h2>
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td>
                          <p>First name</p>
                        </td>
                        <td>
                          <Input
                            label=""
                            {...register("first_name")}
                            error={errors.first_name?.message}
                            placeholder="First name"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Last name</p>
                        </td>
                        <td>
                          <Input
                            label=""
                            {...register("last_name")}
                            error={errors.last_name?.message}
                            placeholder="Last name"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Phone Number</p>
                        </td>
                        <td>
                          <div className="phone-input">
                            <PhoneInput
                              placeholder="Enter phone number"
                              defaultCountry="KE"
                              value={phone}
                              onChange={(e) => setPhone(e)}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Email Address</p>
                        </td>
                        <td>
                          <Input
                            label=""
                            {...register("email")}
                            error={errors.email?.message}
                            placeholder="Email Address"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Company</p>
                        </td>
                        <td>
                          <Input
                            label=""
                            {...register("company")}
                            error={errors.company?.message}
                            placeholder="Company name"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Password</p>
                        </td>
                        <td>
                          <Input
                            label=""
                            type="password"
                            {...register("password")}
                            error={errors.password?.message}
                            placeholder="Password"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Confirm Password</p>
                        </td>
                        <td>
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            className="w-full p-3 mt-2 text-sm border focus:outline-none"
                            onChange={(e) => setPwd(e.target.value)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <button type="submit" className="mt-4 login-btn">
                            {isSubmitting ? "Submitting..." : "Register"}
                          </button>

                          <p className="mt-3">
                            Already have an account?{" "}
                            <Link to="/login">
                              <span className="font-medium main-color">
                                Login here
                              </span>
                            </Link>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Register;
