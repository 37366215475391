import { atom } from "recoil";

export const menuStore = atom({
  key: "menu",
  default: "",
});

export const chatStore = atom({
  key: "chat",
  default: {},
});
