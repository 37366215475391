import { useState, useEffect } from "react";
import { get_request } from "../../hooks/use-fetch";
import { TruckData, TruckListData } from "../../models";
import _ from "lodash";
import { Pagination } from "antd";
import ReactTimeago from "react-timeago";
import { format_date, numberFormatter } from "../../utils";
import SearchTruck from "./search-truck";
import TruckDetails from "./truck-details";
import { socket } from "../communication/socket";

function Trucks() {
  document.title = "Search Truck";
  const [data, setData] = useState<TruckData[]>([]);
  const [total, setTotal] = useState(10);
  const [selected, setSelected] = useState<TruckData>({
    id: 0,
    user_id: 0,
    location: "",
    available_from: "",
    available_to: "",
    full: false,
    truckType: 0,
    weight: 0,
    min_rate: 0,
    contact: "",
    comment: null,
    alarm: false,
    origin: "",
    radius: 0,
    destination: "",
    origin_lat: 0,
    origin_lng: 0,
    destination_lat: 0,
    destination_lng: 0,
    is_contact: false,
    is_email: false,
    is_deleted: false,
    created_at: "",
    update_at: "",
    type: "",
    truck_type: {
      id: 0,
      type: "",
    },
    user: {
      id: 0,
      first_name: "",
      last_ame: "",
      email: "",
      phone: "",
      company: "",
      contact_via_email: false,
      is_deleted: false,
      created_at: "",
    },
    rating: { rating: 0, number: 0 },
  });
  const [params, setParam] = useState<TruckListData>({
    offset: 0,
    items: 10,
    order_by: "id",
    direction: "desc",
    available_from: null,
    available_to: null,
    full: null,
    weight: null,
    radius: null,
    origin_lat: null,
    origin_lng: null,
    location_lat: null,
    location_lng: null,
    truckType: null,
    mine: false,
  });

  const [search, setSearch] = useState<boolean>(false);

  const trucks = async () => {
    const resp = await get_request("truck", params);

    if (resp.success !== undefined && resp.success === 0) {
      // console.log(resp);
    } else {
      setData(resp.data);
      setTotal(resp.count);
    }
  };

  useEffect(() => {
    trucks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const paginate = (page: any, pageSize: any) => {
    params.offset = parseInt(page) - 1;
    params.items = pageSize;
    setParam({ ...params });
  };

  const trucksUpdate = async () => {
    const resp = await get_request("truck", params);

    if (resp.success !== undefined && resp.success === 0) {
    } else {
      if (JSON.stringify(resp.data) !== JSON.stringify(data))
        setData(resp.data);
    }
  };

  useEffect(() => {
    socket.connect();
    socket.on("truck", () => trucksUpdate());
  }, []);

  return (
    <div>
      {search && (
        <SearchTruck
          setSearch={setSearch}
          params={params}
          setParam={setParam}
        />
      )}
      <div className="p-4 bg-white shadow-md">
        <div className="flexBetween">
          <h2 className="font-semibold">Trucks</h2>
          <button className="post-btn" onClick={() => setSearch(true)}>
            New search
          </button>
        </div>

        <div className="pagination-container">
          <div>
            <p>Show</p>
            <Pagination
              onChange={paginate}
              defaultCurrent={1}
              pageSize={params.items}
              current={params.offset + 1}
              total={51}
            />
          </div>
        </div>

        <div className="w-full mt-3 mb-5 overflow-x-auto data-tables">
          <table>
            <thead>
              <tr className="uppercase">
                <th>AGE</th>
                <th>rate</th>
                <th>available</th>
                <th>trip</th>
                <th>Location</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Radius</th>
                <th>Truck type</th>
                <th>Weight</th>
                <th>F/P Load</th>
                <th>company</th>
              </tr>
            </thead>

            <tbody>
              {_.map(data, (t: TruckData, i: number) => (
                <>
                  <tr
                    key={i}
                    onClick={() => setSelected(t)}
                    className={`${selected.id === t.id && "active_row"}`}
                  >
                    <td>
                      <ReactTimeago date={t.created_at} />
                    </td>
                    <td>{numberFormatter(t.min_rate)}</td>
                    <td>
                      {format_date(t.available_from)} -{" "}
                      {format_date(t.available_to)}
                    </td>
                    <td>{t.id}</td>
                    <td>{t.location}</td>
                    <td>{t.origin}</td>
                    <td>{t.destination}</td>
                    <td>{t.radius}</td>
                    <td>{t.type}</td>
                    <td>{t.weight}</td>
                    <td>{t.full ? "Full" : "Partial"}</td>
                    <td>{t.user.company}</td>
                  </tr>
                  {selected.id === t.id && (
                    <tr key={i}>
                      <td colSpan={10}>
                        <TruckDetails data={selected} />
                      </td>
                    </tr>
                  )}
                </>
              ))}
              {data.length === 0 && (
                <tr>
                  <td colSpan={10} className="text-center">
                    No Data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          onChange={paginate}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          defaultCurrent={1}
          current={params.offset + 1}
          pageSize={params.items}
          total={total}
        />
      </div>
    </div>
  );
}

export default Trucks;
