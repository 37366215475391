import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import Inputs from "../../components/input";
import { loginSchema } from "../../schemas";
import { post_request } from "../../hooks/use-fetch";
import { Input, notification } from "antd";
import useToken from "../../hooks/user-token";
import { Link } from "react-router-dom";
import bg from "../../assets/bg2.svg";
import React from "react";

function Login() {
  const [password, setPassword] = React.useState<string>("");
  document.title = "Login";
  const { setToken } = useToken();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<z.input<typeof loginSchema>>({
    resolver: zodResolver(loginSchema, {}, { raw: true }),
  });

  const onSubmit: SubmitHandler<z.input<typeof loginSchema>> = async (data) => {
    if (password.length === 0)
      notification.error({
        message: "Please enter password",
      });
    else {
      const resp = await post_request("auth/login", {
        email: data.email,
        password,
      });

      if (resp.success !== undefined && resp.success === 0)
        notification.error({
          message: resp.message,
        });
      else {
        setToken(resp.token);
        window.location.reload();
      }
    }
  };

  return (
    <div
      className="bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="gap-3 flexColumn">
        <div className="container-box">
          <div className="w-full h-screen md:flexCenter">
            <div className="w-full">
              <div className="grid w-full gap-10 p-10 mt-10 md:items-center md:grid-cols-2">
                <div className="text-white">
                  <h1 className="text-4xl font-bold capitalize">
                    Africa's No 1 load board and factoring Platform.
                  </h1>
                  <p className="mt-6 text-lg md:text-xl">
                    Born out of necessity, Rudi is on a mission to serve
                    shippers, truck owners and drivers by harnessing
                    cutting-edge technology to forge seamless connections
                    between cargo owners, transporters, and other stakeholders.
                  </p>
                </div>
                <div className="gap-3 p-10 bg-white rounded-md flexColumn">
                  <div>
                    <Inputs
                      label="Email Address"
                      {...register("email")}
                      error={errors.email?.message}
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div>
                    <label className="mb-2 regular w-fit">Password</label>
                    <Input.Password
                      type="password"
                      placeholder="Enter your passwrd"
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full p-2.5 active:outline-none text-sm border rounded-none focus:outline-none"
                    />
                  </div>

                  <button type="submit" className="mt-3 login-btn">
                    {isSubmitting ? "Submitting..." : "Login"}
                  </button>
                  <Link to="/register">
                    <p className="login-btn">Register</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
