import { useRecoilValue, useSetRecoilState } from "recoil";
import { menuStore } from "../store";

import { IoMdMenu } from "react-icons/io";
import { useState } from "react";
import { Drawer } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { IoNotificationsOutline } from "react-icons/io5";
import { LuLayoutDashboard } from "react-icons/lu";
import { TbDatabaseSearch } from "react-icons/tb";
import {
  MdOutlineContentPasteSearch,
  MdOutlineContactSupport,
} from "react-icons/md";
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { BsTruck, BsTruckFront } from "react-icons/bs";
import { SlLogout } from "react-icons/sl";
import { LiaLuggageCartSolid } from "react-icons/lia";
import { GiReceiveMoney } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";

function TopNav() {
  const menu = useRecoilValue(menuStore);

  const setMenu = useSetRecoilState(menuStore);

  const location = useLocation();

  const { pathname } = location;

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const ICON_STYLE = "text-xl";
  const MENU = [
    {
      label: "Dashboard",
      icon: <LuLayoutDashboard className={ICON_STYLE} />,
      link: "",
    },
    {
      label: "search loads",
      icon: <TbDatabaseSearch className={ICON_STYLE} />,
      link: "search-load",
    },
    {
      label: "Search Trucks",
      icon: <MdOutlineContentPasteSearch className={ICON_STYLE} />,
      link: "search-truck",
    },
    {
      label: "Post truck",
      icon: <BsTruck className={ICON_STYLE} />,
      link: "post-truck",
    },
    {
      label: "My trucks",
      icon: <BsTruckFront className={ICON_STYLE} />,
      link: "my-trucks",
    },
    {
      label: "Post load",
      icon: <LiaLuggageCartSolid className={ICON_STYLE} />,
      link: "post-load",
    },
    {
      label: "My Loads",
      icon: <FaPersonWalkingLuggage className={ICON_STYLE} />,
      link: "my-loads",
    },
    {
      label: "Finance",
      icon: <GiReceiveMoney className={ICON_STYLE} />,
      link: "finance",
    },
    // {
    //   label: "alarms",
    //   icon: <IoMdAlarm className={ICON_STYLE} />,
    //   link: "alarms",
    // },
    {
      label: "live support",
      icon: <MdOutlineContactSupport className={ICON_STYLE} />,
      link: "support",
    },
    {
      label: "Notifications",
      icon: <IoNotificationsOutline className={ICON_STYLE} />,
      link: "notifications",
    },
    // {
    //   label: "send feedback",
    //   icon: <VscFeedback className={ICON_STYLE} />,
    //   link: "feedback",
    // },
  ];
  return (
    <div className="fixed top-0 z-20 flex justify-between w-full p-4 bg-white shadow-md">
      <div className="flex items-center justify-between w-full">
        <p className="font-semibold capitalize">{menu}</p>
        <IoMdMenu
          className="text-2xl cursor-pointer md:hidden"
          onClick={showDrawer}
        />
      </div>

      <Drawer title="Basic Drawer" onClose={onClose} open={open}>
        <ul className="gap-2 flexColumn ">
          {MENU.map((menu) => (
            <li key={menu.label}>
              <Link
                to={`/${menu.link}`}
                className={`side-nav-menu-item  ${
                  pathname.split("/")[1] === menu.link && "active-menu"
                }`}
                onClick={() => {
                  setMenu(menu.label);
                  setOpen(false);
                }}
              >
                {menu.icon} <p>{menu.label}</p>
              </Link>
            </li>
          ))}
        </ul>
      </Drawer>
    </div>
  );
}

export default TopNav;
