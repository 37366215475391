import { useEffect, useState } from "react";
import { MdOutlineRateReview } from "react-icons/md";
import { get_request, post_request } from "../hooks/use-fetch";
import { Modal, Rate, notification } from "antd";
import { LoadData } from "../models";
import { INPUT_STYLE } from "../constants";

function RateAndReview() {
  const [rate, setRate] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reviewing, setReviewing] = useState(false);
  const [review, setReview] = useState({
    bid_id: 1,
    rating: 1.0,
    review: "",
  });

  const initiateReview = (cargo: any) => {
    setReview({
      bid_id: cargo.bid_id,
      rating: 1.0,
      review: "",
    });
    setReviewing(true);
  };
  const rating = async () => {
    const ratings = await get_request("rating/pending");
    setRate(ratings.data);
  };

  const completeReview = async () => {
    if (review.review.trim().length === 0)
      notification.error({ message: "Please enter comment" });
    else {
      const resp = await post_request("rating/review", review);
      if (resp.success === 0) notification.error({ message: resp.message });
      else {
        setReviewing(false);
        notification.success({ message: resp.message });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
    }
  };
  useEffect(() => {
    let isMounted = true;
    const intervalId = setInterval(() => {
      if (!isMounted) return;
      rating();
    }, 10 * 1000);
    return () => {
      clearInterval(intervalId);
      isMounted = false;
    };
  }, []);
  return (
    <>
      <Modal
        title="Rate and Review Cargo Delivered"
        open={reviewing}
        onOk={() => completeReview()}
        maskClosable={false}
        onCancel={() => setReviewing(false)}
      >
        <div className="flex justify-center p-4">
          <Rate
            className="text-5xl"
            value={review.rating}
            onChange={(e) => setReview({ ...review, ...{ rating: e } })}
          />
        </div>
        <p>Review</p>
        <textarea
          className={INPUT_STYLE}
          placeholder="Enter your review"
          rows={4}
          onChange={(e) => {
            setReview({ ...review, ...{ review: e.target.value } });
          }}
        ></textarea>
      </Modal>
      <Modal
        title="Rate and Review Delivery"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        width={1200}
        maskClosable={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <div className="w-full mt-3 mb-5 overflow-x-auto data-tables">
          <table>
            <thead>
              <tr className="uppercase">
                <th>trip</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Weight (ton)</th>
                <th>Payment Term</th>
                <th>company</th>
                <th>Review</th>
              </tr>
            </thead>

            <tbody>
              {rate.map((t: LoadData, i) => (
                <tr key={i}>
                  <td>{t.id}</td>
                  <td>{t.origin}</td>
                  <td>{t.destination}</td>
                  <td>{t.weight}</td>
                  <td>{t.term}</td>
                  <td>{t.user != null && t.user.company}</td>
                  <td>
                    <button
                      className="delivery-btn"
                      onClick={() => initiateReview(t)}
                    >
                      Review
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
      {rate.length !== 0 && (
        <div
          onClick={() => setIsModalOpen(true)}
          className="absolute z-20 flex items-center gap-3 p-1 bg-white rounded-full shadow-md cursor-pointer shake bottom-6 ps-4 right-6"
        >
          <p className="font-medium">Rate & Review ({rate.length})</p>
          <div className="p-4 text-3xl text-white rounded-full main-bg">
            <MdOutlineRateReview />
          </div>
        </div>
      )}
    </>
  );
}

export default RateAndReview;
