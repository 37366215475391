import { Modal, Progress, Rate } from "antd";
import React, { useState } from "react";
import { LoadData, RatingCategory, ReviewData } from "../../models";
import { get_request } from "../../hooks/use-fetch";
import ReactTimeago from "react-timeago";
import { BsStar, BsStarFill } from "react-icons/bs";

function Rating({ load }: { load: LoadData }) {
  const [reviews, setReviews] = useState<ReviewData[]>([]);
  const [ratingCategory, setRatingCategory] = useState<RatingCategory[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const get_reviews = async () => {
    setIsModalOpen(true);
    const revs = await get_request("rating/reviews?load_id=" + load.id);
    setReviews(revs.data);
    setRatingCategory(revs.ratings);
  };

  return (
    <>
      <Modal
        title={`${load.user?.company} Reviews`}
        open={isModalOpen}
        width={1000}
        footer={[]}
        onCancel={() => setIsModalOpen(false)}
      >
        <div>
          <div>
            {ratingCategory.map((r, i) => (
              <div key={i} className="flex items-center gap-4">
                <div className="flex items-center gap-1">
                  <p>{r.rating}</p>
                  <BsStarFill />
                </div>
                <Progress
                  percent={(r.count / reviews.length) * 100}
                  showInfo={false}
                />
                <p>{r.count}</p>
              </div>
            ))}
          </div>
          {reviews.length !== 0 && (
            <div className="mt-10">
              <p className="mt-3 mb-3 text-lg font-semibold">
                Rating and reviews
              </p>
              {reviews.map((r, i) => (
                <div key={i} className="mb-5">
                  <div className="flex items-center gap-3">
                    <Rate value={r.rating} />
                    <p className="font-semibold">
                      {r.user != null && r.user.company}
                    </p>
                    <p>.</p>
                    <p>
                      <ReactTimeago date={r.created_at} />
                    </p>
                  </div>
                  <p className="mt-3">{r.review}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
      <div
        className="flex items-center gap-2 mt-3 cursor-pointer"
        onClick={() => get_reviews()}
      >
        <Rate
          allowHalf
          className="text-xl"
          value={load.rating.rating}
          disabled
        />
        <p>({load.rating.number})</p>
      </div>
    </>
  );
}

export default Rating;
