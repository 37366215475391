import { useState, useEffect } from "react";
import { get_request } from "../../hooks/use-fetch";
import { CargoListData, LoadData } from "../../models";
import _ from "lodash";
import ReactTimeago from "react-timeago";
import { format_date } from "../../utils";
import SearchLoad from "./search-load";
import LoadDetail from "./load-detail";
import { Pagination } from "antd";
import { socket } from "../communication/socket";

function Loads() {
  document.title = "Search Loads";
  const [data, setData] = useState<LoadData[]>([]);
  const [total, setTotal] = useState(10);
  const [selected, setSelected] = useState<LoadData>({
    id: 0,
    user_id: 0,
    cargo: "",
    weight: 0,
    amount: 0,
    available_from: "",
    available_to: "",
    truck_type: [],
    full: false,
    origin: "",
    alpha3: "",
    destination: "",
    term: "",
    origin_lat: 0,
    origin_lng: 0,
    destination_lat: 0,
    destination_lng: 0,
    created_at: "",
    update_at: "",
    user: null,
    delivered: 0,
    bid: {
      id: 0,
      status: 0,
    },
    bid_requests: [],
    rating: { rating: 0, number: 0 },
  });

  const [params, setParam] = useState<CargoListData>({
    offset: 0,
    items: 10,
    order_by: "id",
    direction: "desc",
    available_from: null,
    available_to: null,
    full: null,
    weight: null,
    radius: null,
    origin_lat: null,
    origin_lng: null,
    destination_lat: null,
    destination_lng: null,
    truckType: null,
    mine: false,
  });

  const [search, setSearch] = useState<boolean>(false);

  const cargo = async () => {
    const resp = await get_request("cargo", params);

    if (resp.success !== undefined && resp.success === 0) {
      // console.log(resp);
    } else {
      setData(resp.data);
      setTotal(resp.count);
    }
  };

  const cargoUpdate = async () => {
    const resp = await get_request("cargo", params);

    if (resp.success !== undefined && resp.success === 0) {
    } else {
      if (JSON.stringify(resp.data) !== JSON.stringify(data))
        setData(resp.data);
    }
  };

  useEffect(() => {
    cargo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const paginate = (page: any, pageSize: any) => {
    params.offset = parseInt(page) - 1;
    params.items = pageSize;
    setParam({ ...params });
  };

  useEffect(() => {
    socket.connect();
    socket.on("cargo", () => {
      console.log("got cargo");
      cargoUpdate();
    });
  }, []);

  return (
    <>
      {search && (
        <SearchLoad setSearch={setSearch} params={params} setParam={setParam} />
      )}
      <div className="p-4 bg-white shadow-md">
        <div className="pagination-container">
          <div>
            <p>Show</p>
            <Pagination
              onChange={paginate}
              defaultCurrent={1}
              pageSize={params.items}
              current={params.offset + 1}
              total={51}
            />
          </div>
        </div>
        <div className="flexBetween">
          <h2 className="font-semibold">Loads</h2>
          <button className="post-btn" onClick={() => setSearch(true)}>
            New search
          </button>
        </div>
        <div className="">
          <div className="w-full mt-3 mb-5 overflow-x-auto data-tables">
            <table>
              <thead>
                <tr className="uppercase">
                  <th>trip</th>
                  <th>AGE</th>
                  <th>Pick up</th>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th>Weight (ton)</th>
                  <th>F/P Load</th>
                  <th>Truck type</th>
                  <th>Payment Term</th>
                  <th>company</th>
                </tr>
              </thead>

              <tbody>
                {_.map(data, (t: LoadData, i: number) => (
                  <>
                    <tr
                      key={i}
                      onClick={() => setSelected(t)}
                      className={`${selected.id === t.id && "active_row"}`}
                    >
                      <td>{t.id}</td>
                      <td>
                        <ReactTimeago date={t.created_at} />
                      </td>
                      <td>{format_date(t.available_from)}</td>
                      <td>{t.origin}</td>
                      <td>{t.destination}</td>
                      <td>{t.weight}</td>
                      <td>{t.full ? "Full" : "Partial"}</td>
                      <td>
                        {_.map(t.truck_type, (tr, i) => (
                          <p key={i}>
                            {"->"} {tr.type}
                          </p>
                        ))}
                      </td>
                      <td>{t.term}</td>
                      <td>{t.user != null && t.user.company}</td>
                    </tr>
                    {selected.id === t.id && (
                      <tr key={i}>
                        <td colSpan={10}>
                          <LoadDetail data={selected} />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
                {data.length === 0 && (
                  <tr>
                    <td colSpan={9} className="text-center">
                      No Data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Pagination
            onChange={paginate}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            defaultCurrent={1}
            current={params.offset + 1}
            pageSize={params.items}
            total={total}
          />
        </div>
      </div>
    </>
  );
}

export default Loads;
