import { getUser } from "../../hooks/user-token";
import { ConversationData } from "../../models";
import { chatStore } from "../../store";
import { useRecoilState } from "recoil";

function ChatItem({ value }: { value: ConversationData }) {
  const [chat, setChat] = useRecoilState(chatStore);

  const chatData: any = chat;

  const selectChat = () => {
    const user = JSON.parse(getUser());
    if (value.user.id !== user.id) setChat(value);
    else setChat(value);
  };

  return (
    <div
      className={`flex items-center gap-3 p-3  bg-white border cursor-pointer ${
        Object.keys(chat).length !== 0 &&
        chatData.id === value.id &&
        "active-chat"
      }`}
      onClick={selectChat}
    >
      <img
        src="https://img.freepik.com/free-psd/3d-illustration-human-avatar-profile_23-2150671122.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1721520000&semt=ais_user"
        alt="avatar"
        className="object-cover rounded-full w-11 h-11 min-w-11"
      />
      <div>
        <p className="text-sm font-medium line-clamp-1">{value.user.company}</p>
        <p className="mt-1 text-sm text-gray-600 line-clamp-2">
          {value.message}
        </p>
      </div>
    </div>
  );
}

export default ChatItem;
