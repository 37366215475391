import { InputHTMLAttributes, forwardRef } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label: string;
}

const Input = forwardRef<HTMLInputElement, Props>(function Input(
  { name, error, label, required, className, ...rest },
  ref
) {
  return (
    <fieldset className={`flex flex-col ${className ? className : ""}`}>
      <label htmlFor={name} className="regular mb-1 w-fit">
        {label} {required && <span className="text-red-500"> *</span>}
      </label>
      <input
        ref={ref}
        id={name}
        name={name}
        {...rest}
        className="w-full border text-sm p-3 focus:outline-none"
      />
      {error && <span className="text-sm text-red-600 mt-1 ps-1">{error}</span>}
    </fieldset>
  );
});

export default Input;
