import { useState } from "react";
import { Modal, Rate, notification } from "antd";
import { post_request } from "../../hooks/use-fetch";
import { INPUT_STYLE } from "../../constants";
import { BidDetail } from "../../models";

function RateAndReviewTruck({ bid }: { bid: BidDetail }) {
  const [reviewing, setReviewing] = useState(false);
  const [review, setReview] = useState({
    bid_id: bid.id,
    rating: 1.0,
    review: "",
  });

  const completeReview = async () => {
    if (review.review.trim().length === 0)
      notification.error({ message: "Please enter comment" });
    else {
      const resp = await post_request("cargo/delivered", review);
      if (resp.success === 0) notification.error({ message: resp.message });
      else {
        setReviewing(false);
        notification.success({ message: resp.message });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
    }
  };

  return (
    <>
      <button className="delivery-btn" onClick={() => setReviewing(true)}>
        Delivered Cargo
      </button>

      <Modal
        title="Rate and Review Truck Delivery"
        open={reviewing}
        onOk={() => completeReview()}
        maskClosable={false}
        onCancel={() => setReviewing(false)}
      >
        <div className="flex justify-center p-4">
          <Rate
            className="text-5xl"
            value={review.rating}
            onChange={(e) => setReview({ ...review, ...{ rating: e } })}
          />
        </div>
        <p>Review</p>
        <textarea
          className={INPUT_STYLE}
          placeholder="Enter your review"
          rows={4}
          onChange={(e) => {
            setReview({ ...review, ...{ review: e.target.value } });
          }}
        ></textarea>
      </Modal>
    </>
  );
}

export default RateAndReviewTruck;
