import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useState } from "react";
import { LocationType } from "../models";

const defaultMapContainerStyle = {
  width: "100%",
  height: "300px",
};

const defaultMapZoom = 18;

const defaultMapOptions = {
  zoomControl: true,
  tilt: 0,
  gestureHandling: "auto",
  mapTypeId: "satellite",
};

type LocationProps = {
  location: LocationType;
  setLocation: (location: LocationType) => void;
};

const MapComponent = ({ location, setLocation }: LocationProps) => {
  const [mapref, setMapRef] = useState<google.maps.Map>();

  const [center, setCenter] = useState({
    lat: location.lat,
    lng: location.lng,
  });

  // const handleBoundsChanged = () => {
  //   if (refMap.current) {
  //     const mapCenter = refMap.current.getCenter(); //get map center
  //     setCenter(mapCenter);
  //     alert("yah");
  //   }
  // };

  const handleOnLoad = (map: any) => {
    setMapRef(map);
  };

  const handleBoundsChanged = () => {
    if (mapref) {
      setCenter({
        lat: parseFloat(mapref.getCenter()?.lat() + ""),
        lng: parseFloat(mapref.getCenter()?.lng() + ""),
      });
    }
  };

  return (
    <div className="w-full mt-3">
      <GoogleMap
        onBoundsChanged={handleBoundsChanged}
        onDragEnd={() => {
          location.lat = center.lat;
          location.lng = center.lng;
          setLocation({ ...location });
        }}
        mapContainerStyle={defaultMapContainerStyle}
        center={location}
        zoom={defaultMapZoom}
        options={defaultMapOptions}
        onLoad={handleOnLoad}
      >
        <MarkerF position={center} />
      </GoogleMap>
    </div>
  );
};

export { MapComponent };
