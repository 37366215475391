import { Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { FaMessage } from "react-icons/fa6";
import { socket } from "./socket";
import { getUser } from "../../hooks/user-token";

const { TextArea } = Input;
export default function StartChat({
  chat_with,
  company,
}: {
  chat_with: string;
  company: string;
}) {
  const [isChat, setIsChat] = useState(false);

  const [message, setMessage] = useState("");

  const submitMessage = () => {
    const user = JSON.parse(getUser());
    socket.emit("message", { to: chat_with, from: user.id, message });
    setMessage("");
    setIsChat(false);
  };

  useEffect(() => {
    socket.connect();
  }, []);
  return (
    <>
      <Modal
        title={`Message ${company}`}
        open={isChat}
        onOk={submitMessage}
        maskClosable={false}
        onCancel={() => setIsChat(false)}
      >
        <div>
          <TextArea
            placeholder="Enter message here.."
            value={message}
            defaultValue={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </Modal>
      <div
        className="mt-3 text-xl cursor-pointer main-color"
        onClick={() => setIsChat(true)}
      >
        <FaMessage />
      </div>
    </>
  );
}
