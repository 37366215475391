import { MapComponent } from "./map";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { LocationType } from "../models";
import { MapProvider } from "../providers/map-provider";

type LocationProps = {
  placeholder: string;
  location: LocationType;
  setLocation: (location: LocationType) => void;
};
function LocationPicker({ placeholder, location, setLocation }: LocationProps) {
  const handleChange = (addr: string) => {
    location.location = addr;
    if (addr.length === 0) location.lat = 0;
    setLocation({ ...location });
  };

  const handleSelect = (address: string) => {
    location.location = address;
    setLocation({ ...location });

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        location.lat = latLng.lat;
        location.lng = latLng.lng;
        setLocation({ ...location });
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <div>
      <PlacesAutocomplete
        value={location.location}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              required
              {...getInputProps({
                placeholder: placeholder,
                className: `w-full px-3 py-2 mt-1 text-sm border rounded-sm focus:outline-none`,
              })}
            />
            <div className="autocomplete-dropdown-containers">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                    }
                  : {
                      backgroundColor: "var(--div-bg)",
                      cursor: "pointer",
                    };
                return (
                  <div
                    // key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <p className=" location-suggestion">
                      {suggestion.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {location.location.length !== 0 && location.lat !== 0 && (
        <MapProvider>
          <MapComponent location={location} setLocation={setLocation} />
        </MapProvider>
      )}
    </div>
  );
}

export default LocationPicker;
