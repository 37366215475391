import { useEffect, useState } from "react";
import { ListDataItems, NotificationData } from "../../models";
import { Pagination } from "antd";
import { get_request } from "../../hooks/use-fetch";
import _ from "lodash";
import ReactTimeago from "react-timeago";

function Notifications() {
  document.title = "Search Loads";
  const [data, setData] = useState<NotificationData[]>([]);
  const [total, setTotal] = useState(10);

  const [params, setParam] = useState<ListDataItems>({
    offset: 0,
    items: 10,
  });

  const paginate = (page: any, pageSize: any) => {
    params.offset = parseInt(page) - 1;
    params.items = pageSize;
    setParam({ ...params });
  };

  const notiifcations = async () => {
    const resp = await get_request("data/notifications", params);

    if (resp.success !== undefined && resp.success === 0) {
      // console.log(resp);
    } else {
      setData(resp.data);
      setTotal(resp.count);
    }
  };

  useEffect(() => {
    notiifcations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <div className="p-4 bg-white shadow-md">
      <div className="mb-3 flexBetween">
        <h2 className="font-semibold">Notifications</h2>
      </div>
      <div className="pagination-container">
        <div>
          <p>Show</p>
          <Pagination
            onChange={paginate}
            defaultCurrent={1}
            pageSize={params.items}
            current={params.offset + 1}
            total={51}
          />
        </div>
      </div>

      <div className="">
        <div className="w-full mt-3 mb-5 overflow-x-auto data-tables">
          <table>
            <thead>
              <tr className="uppercase">
                <th>No</th>
                <th>title</th>
                <th>message</th>
                <th>On</th>
              </tr>
            </thead>

            <tbody>
              {_.map(data, (t: NotificationData, i: number) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{t.title}</td>
                  <td>{t.message}</td>
                  <td>
                    <ReactTimeago date={t.created_at} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination
          onChange={paginate}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          defaultCurrent={1}
          current={params.offset + 1}
          pageSize={params.items}
          total={total}
        />
      </div>
    </div>
  );
}

export default Notifications;
