import { useEffect, useState } from "react";
import { DatePicker } from "antd";
import { CargoListData, LocationType, SelectData } from "../../models";
import LocationPicker from "../../components/location-picker";
import { getTruckTypes } from "../../services";
import _ from "lodash";

const { RangePicker } = DatePicker;
function SearchLoad({
  setSearch,
  params,
  setParam,
}: {
  setSearch: (search: boolean) => void;
  params: CargoListData;
  setParam: (args: CargoListData) => void;
}) {
  const [data, setData] = useState(params);

  const [origin, setOrigin] = useState<LocationType>({
    location: "",
    lat: 0,
    lng: 0,
  });
  const [destination, setDestination] = useState<LocationType>({
    location: "",
    lat: 0,
    lng: 0,
  });

  const INPUT_STYLE =
    "w-full px-3 py-2 mt-1 text-sm border rounded-sm focus:outline-none";

  const [types, setTypes] = useState<SelectData[]>([]);

  const truckTypes = async () => {
    const data = await getTruckTypes();
    setTypes(data);
  };

  useEffect(() => {
    truckTypes();
  }, []);

  useEffect(() => {
    data.origin_lat = origin.lat === 0 ? null : origin.lat;
    data.origin_lng = origin.lng === 0 ? null : origin.lng;
    setData({ ...data });
  }, [origin]);

  useEffect(() => {
    data.destination_lat = destination.lat === 0 ? null : destination.lat;
    data.destination_lng = destination.lng === 0 ? null : destination.lng;
    setData({ ...data });
  }, [destination]);

  const onSearch = () => {
    params = data;
    setParam({ ...params });
  };

  return (
    <div className="py-4">
      <div className="p-6 bg-white shadow-md">
        <h2 className="text-lg font-semibold">Search Loads</h2>
        <div className="grid grid-cols-1 gap-6 mt-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 regular">
          <div>
            <p>Date Range</p>
            <RangePicker
              className="w-full mt-1 rounded-none h-9"
              onChange={(e, p) => {
                data.available_from = p[0];
                data.available_to = p[1];
                setData({ ...data });
              }}
            />
          </div>
          <div>
            <p>Full / Patial</p>
            <select
              className={INPUT_STYLE}
              onChange={(e) => {
                data.full =
                  e.target.value === "2" ? null : e.target.value === "1";
                setData({ ...data });
              }}
            >
              <option value={2}>Full & Partial</option>
              <option value={1}>Full</option>
              <option value={0}>Partial</option>
            </select>
          </div>
          <div>
            <p>Truck type</p>
            <select
              className={INPUT_STYLE}
              defaultValue={""}
              onChange={(e) => {
                data.truckType = e.target.value;
                setData({ ...data });
              }}
            >
              <option value="">All types</option>
              {_.map(types, (t, i) => (
                <option value={t.value} key={i}>
                  {t.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p>Weight in Tons</p>
            <input
              type="number"
              className={INPUT_STYLE}
              placeholder="Truck weight"
              onChange={(e) => {
                data.weight =
                  e.target.value === "" ? null : parseFloat(e.target.value);
                setData({ ...data });
              }}
            />
          </div>

          <div>
            <p>Origin</p>
            <LocationPicker
              placeholder="Origin location"
              location={origin}
              setLocation={setOrigin}
            />
          </div>
          <div>
            <p>Destination</p>
            <LocationPicker
              placeholder="Destination location"
              location={destination}
              setLocation={setDestination}
            />
          </div>

          {(origin.location.length > 0 || destination.location.length > 0) && (
            <div>
              <p>Location Radius limit (Km)</p>
              <input
                type="text"
                className={INPUT_STYLE}
                placeholder="Radius (100km)"
                onChange={(e) => {
                  data.radius =
                    e.target.value === "" ? null : parseFloat(e.target.value);
                  setData({ ...data });
                }}
              />
            </div>
          )}
        </div>

        <div className="mt-5 flexEnd">
          <button
            className="cancel-btn"
            onClick={() => {
              setParam({
                offset: 0,
                items: 10,
                order_by: "id",
                direction: "desc",
                available_from: null,
                available_to: null,
                full: null,
                weight: null,
                radius: null,
                origin_lat: null,
                origin_lng: null,
                destination_lat:null,
                destination_lng:null,
                truckType: null,
                mine: params.mine,
              });
              setSearch(false);
            }}
          >
            Cancel
          </button>
          <button className="post-btn" onClick={onSearch}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchLoad;
